.apollo-card-image {
  width: 100%;
}

.apollo-card-image-top {
  width: 100%;
  border-top-left-radius: calc(.25rem - 1px);
  border-top-right-radius: calc(.25rem - 1px);
}

.apollo-card-image-bottom {
  width: 100%;
  border-bottom-right-radius: calc(.25rem - 1px);
  border-bottom-left-radius: calc(.25rem - 1px);
}

.apollo-card-image-left {
  height: 100%;
  border-top-left-radius: calc(.25rem - 1px);
  border-bottom-left-radius: calc(.25rem - 1px);
}

.apollo-card-image-right {
  height: 100%;
  border-top-right-radius: calc(.25rem - 1px);
  border-bottom-right-radius: calc(.25rem - 1px);
}