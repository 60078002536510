.basic-alert {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-left: 5px solid #f5c6cb;
    margin-top: 10px;
    padding: 0px 10px;
}

.basic-alert h5,
.basic-alert p
{
    color: var(--danger);
    margin-bottom: 0px;

    animation: blink 0.5s;
    animation-iteration-count: 2;
}

.basic-alert--bottom-spacing {
    margin-bottom: 1em;
}

@keyframes blink {
    50% { color: white; }
}