.apollo-form-group {
  display: inherit;
  padding-right: 1.5rem;
  padding-top: 7.5px;
  padding-bottom: 7.5px;
  vertical-align: top;
}

.apollo-form-group:last-child {
  padding-right: 0;
}

.apollo-form-group-inline {
  display: inline-block;
}

.apollo-form-group-inline label {
  display: inherit;
}

.apollo-form-group-check label {
  font-size: 16px;
  font-weight: 100;
}