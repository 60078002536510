.apollo-collapse {
  display: none;
}

.apollo-collapse.apollo-open {
  display: block;
}

.apollo-navbar-collapse  {
  -ms-flex-preferred-size: 100%;
  flex-basis: 100%;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.apollo-navbar-collapse.apollo-open {
  padding-bottom: 1rem;
}

.apollo-navbar-collapse.apollo-open .apollo-navbar-nav .apollo-nav-link,
.apollo-navbar-collapse.apollo-open .apollo-navbar-nav .apollo-dropdown-toggle {
  line-height: 35px;
  padding: .5rem 0;
}
