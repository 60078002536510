.apollo-avatar {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  color: var(--white, white);
  background-clip: padding-box;
  background-size: cover;
  background-position: center center;
  background-color: var(--day, blue);
  border-radius: 50%;
  margin: auto;
  overflow: hidden;
  text-align: center;
  user-select: none;
}

.apollo-avatar:focus {
  outline: none;
}

.apollo-avatar-x-small {
  height: 28px;
  width: 28px;
  line-height: 28px;
}

.apollo-avatar-small {
  height: 40px;
  width: 40px;
  line-height: 40px;
}

.apollo-avatar-medium {
  height: 48px; 
  width: 48px;
  line-height: 48px;
}

.apollo-avatar-large {
  height: 64px;
  width: 64px;
  line-height: 64px;
}

.apollo-avatar-x-large {
  height: 88px;
  width: 88px;
  line-height: 88px;
}

.apollo-avatar > span {
  font-weight: 100;
  margin: auto;
  padding: 0;
}

.apollo-avatar-x-small > span {
  font-size: 14px;
}

.apollo-avatar-small > span {
  font-size: 16px;
}

.apollo-avatar-medium > span {
  font-size: 20px;
}

.apollo-avatar-large > span {
  font-size: 24px;
}

.apollo-avatar-x-large > span {
  font-size: 32px;
}
