.apollo-input {
  background-color: var(--white, white);
  border: 1px solid var(--dark-gray, darkgray);
  border-radius: 6px;
  font-family: inherit;
  font-size: 16px;
  height: 49.33px;
  line-height: inherit;
  margin: 0;
  overflow: visible;
  padding: 0 1rem;
  max-width: 370px;
  width: 100%;
}

.apollo-input::-ms-clear {
  display: none;
}

.apollo-input:focus {
  outline: none;
}

.apollo-input:hover,
.apollo-input:focus {
  border-color: var(--day, blue);
}

.apollo-input:disabled {
  background-color: var(--light-smoke, whitesmoke);
  border-color: var(--marble, lightgray);
}

.apollo-input[type=number]::-webkit-inner-spin-button {
  display: none;
}

::placeholder {
  color: var(--dark-gray, darkgray) !important;
}
