.apollo-alert {
  height: 64px;
  width: 100%;
  background-color: var(--white, white);
  border: 1px solid var(--marble, lightgray);
  border-radius: 8px;
  position: relative;
  display: block;
  margin-top: 5px;
  margin-bottom: 5px;
}

.apollo-alert:first-child {
  margin-top: 0;
}

.apollo-alert:last-child {
  margin-bottom: 0;
}

.apollo-alert-icon {
  display: inline-block;
  height: 100%;
  width: 64px;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
}

.apollo-alert-primary .apollo-alert-icon {
  background-color: var(--day, white);
}

.apollo-alert-secondary .apollo-alert-icon {
  background-color: var(--white, white);
}

.apollo-alert-danger .apollo-alert-icon {
  background-color: var(--danger, red);
}

.apollo-alert-success .apollo-alert-icon {
  background-color: var(--success, green);
}

.apollo-alert-warning .apollo-alert-icon {
  background-color: var(--warning, yellow);
}

.apollo-alert-info .apollo-alert-icon {
  background-color: var(--morning, lightblue);
}

.apollo-alert-body {
  display: inline-flex;
  align-items: center;
  padding: 0 0 0 20px;
  height: 100%;
  vertical-align: top;
  overflow-x: hidden;
  line-height: normal;
  word-break: break-all;
  width: calc(100% - 64px - 64px);
}

.apollo-alert .apollo-close {
  position: absolute;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  float: right;
  top: 0px;
  right: 0px;
  line-height: 60px;
  height: 64px;
  width: 64px;
}

.apollo-alert .apollo-close:hover,
.apollo-alert .apollo-close:focus {
  border-color: var(--sky, lightblue);
  outline: none;
  display: inline-block;
}

.apollo-alert .apollo-close:after {
  content: '×';
  display: inline-block;
  color: var(--stone, darkgray);
  font-size: 42px;
  line-height: 48px;
  font-weight: lighter;
}
