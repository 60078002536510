.container {
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-left: auto;
  margin-right: auto;
}

@media (--mobile-only) {
  .container {
    max-width: 767px;
  }
}

@media (--tablet-only) {
  .container {
    max-width: 1024px;
  }
}

@media (--desktop-only) {
  .container {
    max-width: 1280px;
  }
}

@media (--large-desktop) {
  .container {
    padding-right: 88px;
    padding-left: 88px;
    max-width: 1440px;
  }
}

.container-fluid { 
  width: 100%;
  padding-right: 20px;
  padding-left: 20px;
  margin-left: auto;
  margin-right: auto;
}
