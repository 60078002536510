.apollo-checkbox {
  display: inline-block;
  padding-right: 35px;
  position: relative;
  user-select: none;
}

.apollo-checkbox input {
  height: 0;
  opacity: 0;
  padding: 0;
  width: 0;
}

.apollo-checkbox input:disabled {
  cursor: default;
}

.apollo-checkbox .apollo-checkmark {
  border: 1px solid var(--stone, slategray);
  border-radius: 3px;
  height: 20px;
  left: 0;
  position: absolute;
  top: 3px;
  width: 20px;
}

.apollo-checkbox:hover input ~ .apollo-checkmark {
  border: 2px solid var(--day, blue);
  cursor: pointer;
  height: 20px;
  width: 20px;
}

.apollo-checkbox input:checked ~ .apollo-checkmark {
  background-color: var(--day, blue);
  border: 2px solid var(--day, blue);
  height: 18px;
  width: 18px;
}

.apollo-checkbox input:disabled ~ .apollo-checkmark {
  background-color: var(--misty, lightgray);
  border-color: var(--misty, lightgray);
  cursor: default;
}

.apollo-checkbox .apollo-checkmark:after {
  content: "";
  display: none;
  position: absolute;
}

.apollo-checkbox input:checked ~ .apollo-checkmark:after {
  display: block;
}

.apollo-checkbox .apollo-checkmark:after {
  border: solid var(--white, white);
  border-width: 0 2px 2px 0;
  height: 10px;
  left: 5px;
  top: 1px;
  width: 4px;
  transform: rotate(45deg);
}
