.register-error {
  width: 100%;
  display:flex;
  justify-content: center;
  padding: 5px;
  flex-flow: row nowrap;
  background-color: rgba(231, 77, 60, 0.897);
  border-radius: 7px;
  margin-top: 75px;
}

.register-error-message {
  max-width: 80%;
  text-align: center;
  color: white;
}

@media (--mobile-only) {
  .register-error {
    margin-top: 5px;
  }
}