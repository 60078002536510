.terms-of-use-page {
    margin-top: 90px;
  }
  
  .terms-of-use-modal {
    min-height: 25px;
    overflow: auto;
    max-height: 47vh;
  }
  
  .terms-of-use-page a,
  .terms-of-use-modal a {
    color: #025FAE;
  }
  