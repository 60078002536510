.apollo-tag {
  display: inline-flex;
  position: relative;
  overflow: hidden;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  background-color: var(--cloud, lightblue);
  color: var(--black, black);
  margin-left: 2.5px;
  margin-right: 2.5px;
  border-radius: 100px;
  height: 40px;
  font-size: 16px;
  font-weight: 300;
  line-height: 16px;
  vertical-align: top;
  outline: none;
}

.apollo-tag:first-child {
  margin-left: 0;
  margin-right: 2.5px;
}

.apollo-tag:last-child {
  margin-right: 0;
  margin-left: 2.5px;
}

.apollo-tag-icon {
  display: block;
  margin-left: -10px;
  height: 40px;
  width: 40px;
  border-radius: 100%;
  border: 1px solid var(--white, white);
}

.apollo-tag-text { 
  padding-left: 10px;
  padding-right: 10px;
}

.apollo-tag-icon ~ .apollo-tag-text {
  padding-left: 5px;
}

.apollo-tag .apollo-close {
  position: absolute;
  background-color: var(--cloud, lightblue);
  border: 1px solid transparent;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  cursor: pointer;
  height: 40px;
  width: 40px;
  right: 10px;
  margin-right: -10px;
  padding-right: 10px;
  display: none;
}

.apollo-tag:hover .apollo-close,
.apollo-tag:focus .apollo-close {
  display: block;
}

.apollo-tag .apollo-close:hover,
.apollo-tag .apollo-close:focus {
  border-color: var(--sky, lightblue);
  outline: none;
  display: block
}

.apollo-tag .apollo-close:after {
  content: '×';
  display: inline-block;
  color: var(--stone, darkgray);
  font-size: 28px;
  line-height: 24px;
  font-weight: lighter;
}

.apollo-tag-secondary {
  background-color: var(--cloud, lightblue);
  color: var(--black, black);
}

.apollo-tag-primary {
  background-color: var(--sky, lightblue);
}

.apollo-tag-primary .apollo-close {
  background-color: var(--sky, lightblue);
}

.apollo-tag-primary .apollo-close:hover,
.apollo-tag-primary .apollo-close:focus {
  border-color: var(--morning, blue);
  outline: none;
  display: block
}


