.apollo-breadcrumb {
  color: var(--day);
  cursor: pointer;
  text-decoration: none;
}

.apollo-breadcrumb:not(.apollo-active):hover {
  text-decoration: underline;
}

.apollo-breadcrumb::before {
  content: "/";
  color: var(--black);
  display: inline-block;
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}

.apollo-breadcrumb:hover::before {
  text-decoration: none;
}

.apollo-breadcrumb.apollo-active {
  color: var(--morning);
  cursor: initial;
}