.register-future-info-form .apollo-form-group:last-child {
  padding-right: 1.5rem;
}

.register-future-info-form .apollo-form-group:last-child label {
  float: left;
}

.register-future-info-form .apollo-form-group:last-child .apollo-form-text {
  float: right;
}

.register-future-info-form .apollo-form-group .labels {
  display: flex;
  justify-content: space-between;
  max-width:370px;
}

@media (--mobile-only) {
  .register-future-info-form .apollo-form-group:last-child {
    padding-right: 0;
  }

  .register-future-info-form .apollo-form-group .labels,
  .register-future-info-form .apollo-form-group input,
  .register-future-info-form .apollo-form-group .apollo-select {
    max-width: unset;
  }
}