.register {
    position: relative;
    margin-top: 100px;
  }
  
  .register-content {
    min-height: calc(100vh - 310px);
  }
  
  .register-content h5 {
    text-align: left;
    padding-top: 32px;
  }
  
  .register-content p {
    text-align: left;
  }
  
  .register-content a {
    color: #3D7796;
  }
  
  .register-content .apollo-form-text {
    text-align: left;
  }
  .register-content .apollo-form-text {
    margin-left: 0;
  }
  
  @media (--mobile-only) {
    .register {
      position: static;
    }
  
    .register .apollo-container {
      justify-content: center;
    }
  
    .register .apollo-container > a {
      padding-right: 0;
    }
  
    .register-mobile-container {
      margin-bottom: 123px;
    }
  
    .register-content .apollo-form-group {
      padding-right: 0;
    }
  
    .register-content p {
      text-align: center;
    }
  
    .register-content h2 {
      font-size: 1.25em;
      text-align: center;
    }
  }