/* https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
   https://www.paulirish.com/2012/box-sizing-border-box-ftw/ 
*/
html {
    box-sizing: border-box;
}

*, *:before, *:after {
    box-sizing: inherit;
}
/* end of border box trick */


html {
    min-height: 100%; /* needed for footer */
    position: relative;
    -webkit-tap-highlight-color: transparent;
}

html,
body {
    line-height: 1.5rem;  
    margin: 0;
    padding: 0;
    font-family: LotaGrotesque-Light;
}

body {
    overflow-y: scroll;
}

a {
    color: inherit;
    text-decoration: none;
}

b, strong {
    font-weight: bold;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
  margin-bottom: .5em;
  color: var(--black, black);
	font-weight: normal;
	font-style: normal;  
}

h1,
h2,
h3,
h4 {
  font-family: Campton-Book;
}

h5 {
  font-family: Campton-Medium;
}

h1 {
  font-size: 48px;
  line-height: 54px;
}

h2 {
  font-size: 32px;
  line-height: 40px;
}

h3 {
  font-size: 26px;
  line-height: 34px;
}

h4 {
  font-size: 18px;
  line-height: 24px;
}

h5 {
  font-size: 16px;
  line-height: 22px;
}

h6 {
  font-size: 14px;
  line-height: 20px;
}

p {
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 1rem;
  margin-top: 0;
}

small {
	font-size: 14px;
	font-weight: 300;
	line-height: 21px;
}

label {
    font-family: LotaGrotesque-SemiBold;
}
