.create-guest-account .error {
    color: red;
  }
  
  .create-guest-account .footer {
    height: 115px;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    justify-content: flex-start;
    box-shadow: 0 0 16px 0 rgba(28,37,49,0.08);
    background-color: white;
  }
  
  .create-guest-account .controls {
    position: relative;
    height: 100%;
  }
  
  .create-guest-account .control {
    position: absolute;
    bottom: 0;
    margin: 25px 0px;
  }
  
  .create-guest-account .control:first-child {
    left: 25px;
  }
  
  .create-guest-account .control:last-child {
    right: 25px;
  }
  