.apollo-navbar {
  position: relative;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0;
  height: 70px;
}

.apollo-navbar > .apollo-container,
.apollo-navbar > .apollo-container-fluid {
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.apollo-navbar .apollo-nav-horizontal-left {
  padding-left: 24px;
}

.apollo-navbar .apollo-nav-horizontal-right {
  padding-right: 24px;
}

@media (min-width: 576px) {
  .apollo-navbar-expand-small {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .apollo-navbar-expand-small .apollo-navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .apollo-navbar-expand-small .apollo-navbar-nav .apollo-dropdown-menu {
    position: absolute;
  }
  .apollo-navbar-expand-small .apollo-navbar-nav .apollo-nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .apollo-navbar-expand-small > .apollo-container,
  .apollo-navbar-expand-small > .apollo-container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .apollo-navbar-expand-small .apollo-navbar-collapse {
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .apollo-navbar-expand-small .apollo-navbar-toggle {
    display: none;
  }
}

@media (min-width: 768px) {
  .apollo-navbar-expand-medium {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .apollo-navbar-expand-medium .apollo-navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .apollo-navbar-expand-medium .apollo-navbar-nav .apollo-dropdown-menu {
    position: absolute;
  }
  .apollo-navbar-expand-medium .apollo-navbar-nav .apollo-nav-link,
  .apollo-navbar-expand-medium .apollo-navbar-nav .apollo-dropdown-toggle {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .apollo-navbar-expand-medium > .apollo-container,
  .apollo-navbar-expand-medium > .apollo-container-fluid {
    -ms-flex-wrap: nowrap;
    flex-wrap: nowrap;
  }
  .apollo-navbar-expand-medium .apollo-navbar-collapse {
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .apollo-navbar-expand-medium .apollo-navbar-toggle {
    display: none;
  }
}

@media (min-width: 992px) {
  .apollo-navbar-expand-large {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .apollo-navbar-expand-large .apollo-navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .apollo-navbar-expand-large .apollo-navbar-nav .apollo-dropdown-menu {
    position: absolute;
  }
  .apollo-navbar-expand-large .apollo-navbar-nav .apollo-nav-link,
  .apollo-navbar-expand-large .apollo-navbar-nav .apollo-dropdown-toggle {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .apollo-navbar-expand-large .apollo-navbar-collapse {
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .apollo-navbar-expand-large .apollo-navbar-toggle {
    display: none;
  }
}

@media (min-width: 1200px) {
  .apollo-navbar-expand-x-large {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
  }
  .apollo-navbar-expand-x-large .apollo-navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
  }
  .apollo-navbar-expand-x-large .apollo-navbar-nav .apollo-dropdown-menu {
    position: absolute;
  }
  .apollo-navbar-expand-x-large .apollo-navbar-nav .apollo-nav-link,
  .apollo-navbar-expand-x-large .apollo-navbar-nav .apollo-dropdown-toggle {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .apollo-navbar-expand-x-large .apollo-navbar-collapse {
    display: flex !important;
    -ms-flex-preferred-size: auto;
    flex-basis: auto;
  }
  .apollo-navbar-expand-x-large .apollo-navbar-toggle {
    display: none;
  }
}

.apollo-navbar-expand {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-flow: row nowrap;
  flex-flow: row nowrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.apollo-navbar-expand .apollo-navbar-nav {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  padding: 0 25px;
}

.apollo-navbar-expand .apollo-nav-item {
  padding-top: 0;
  padding-bottom: 0;
}

.apollo-navbar-expand .apollo-navbar-nav .apollo-dropdown-menu {
  position: absolute;
}

.apollo-navbar-expand .apollo-navbar-nav .apollo-nav-link,
.apollo-navbar-expand .apollo-navbar-nav .apollo-dropdown-toggle {
  padding-right: 1rem;
  padding-left: 1rem;
}

.apollo-navbar-expand .apollo-navbar-collapse {
  display: flex !important;
  -ms-flex-preferred-size: auto;
  flex-basis: auto;
}

.apollo-navbar-expand .apollo-navbar-toggle {
  display: none;
}

.apollo-navbar-fixed-top {
  transform : translate3d(0, 0, 0);
  position: fixed;
  top: 0;
  left: 0;
  right: 0; 
  z-index: 1030;
}

.apollo-navbar-fixed-bottom {
  transform : translate3d(0, 0, 0);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1030;
}

.apollo-navbar-dark {
  background-color: #323232;
}

.apollo-navbar-dark .apollo-navbar-toggle {
  color: var(--charcoal, slategray);
  border-color: var(--dark-gray, darkgray);
}

.apollo-navbar-dark .apollo-navbar-toggle-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(255, 255, 255, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.apollo-navbar-dark .apollo-navbar-collapse.open .apollo-navbar-nav .apollo-dropdown-menu {
  border-color: transparent;
  background-color: var(--black, black);
}

.apollo-navbar-dark .apollo-navbar-collapse.open .apollo-navbar-nav .apollo-dropdown-item {
  color: var(--misty, lightgray);
}

.apollo-navbar-dark .apollo-navbar-collapse.open .apollo-navbar-nav .apollo-dropdown-item:hover,
.apollo-navbar-dark .apollo-navbar-collapse.open .apollo-navbar-nav .apollo-dropdown-item:focus {
  color: var(--black, black);
}

.apollo-navbar-light {
  background-color: var(--white, white);
  box-shadow: 0 0 12px 0 rgba(0,0,0,0.12);
}

.apollo-navbar-light .apollo-navbar-toggle {
  color: var(--charcoal, slategray);
  border-color: var(--dark-gray, darkgray);
}

.apollo-navbar-light .apollo-navbar-toggle-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgba(0, 0, 0, 0.5)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
}

.apollo-navbar-light .apollo-navbar-collapse.open .apollo-navbar-nav .apollo-dropdown-menu {
  border-color: transparent;
  background-color: var(--white, white);
}

.apollo-navbar-fixed-top.apollo-navbar-light {
  box-shadow: 0 0 12px 0 rgba(0,0,0,0.12);
}

.apollo-navbar-fixed-bottom.apollo-navbar-light {
  box-shadow: 0 0 -12px 0 rgba(0,0,0,0.12);
}

.apollo-navbar-dark .apollo-navbar-brand {
  padding-right: 24px;
  color: var(--white, white);
}

.apollo-navbar-light .apollo-navbar-brand {
  padding-right: 24px;
  color: var(--black, black);
  border-right: 1px solid var(--marble, lightgray);
}

.apollo-navbar-dark .apollo-nav-link,
.apollo-navbar-dark .apollo-dropdown-toggle {
  color: var(--misty, lightgray);
}

.apollo-navbar-dark .apollo-nav-link.apollo-active,
.apollo-navbar-dark .apollo-nav-link:active,
.apollo-navbar-dark .apollo-dropdown-toggle.apollo-active,
.apollo-navbar-dark .apollo-dropdown-toggle:active {
  color: var(--white, white);
  text-shadow: 0 0 .01rem var(--white, white),
               0 0 .01rem var(--white, white);
}

.apollo-navbar-dark .apollo-nav-link:hover,
.apollo-navbar-dark .apollo-nav-link:focus,
.apollo-navbar-dark .apollo-dropdown-toggle:hover,
.apollo-navbar-dark .apollo-dropdown-toggle:focus {
  color: var(--white, white);
}

.apollo-navbar-dark .apollo-nav-link.apollo-disabled,
.apollo-navbar-dark .apollo-nav-link:disabled,
.apollo-navbar-dark .apollo-dropdown-toggle.apollo-disabled,
.apollo-navbar-dark .apollo-dropdown-toggle:disabled {
  color: var(--charcoal, slategray);
  text-shadow: none;
}
