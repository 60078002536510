.apollo-modal-header {
  position: relative;
  padding: 1rem;
}

.apollo-modal-title {
  line-height: 1rem;
  margin-bottom: 0;
  font-weight: 300;
  font-size: 26px;
  color: var(--black, black);
}

.apollo-modal-header .apollo-close {
  position: absolute;
  background-color: transparent;
  border: 1px solid transparent;
  cursor: pointer;
  float: right;
  top: 0px;
  right: 0;
  height: 40px;
  width: 40px;
}

.apollo-modal-header .apollo-close:hover,
.apollo-modal-header .apollo-close:focus {
  outline: none;
}

.apollo-modal-header .apollo-close:after {
  content: '×';
  display: inline-block;
  color: var(--black, black);
  font-size: 28px;
  line-height: 40px;
  font-weight: lighter;
}
