.apollo-form-feedback {
  color: var(--danger, red);
  font-size: 13px;
  text-align: left;
}

.apollo-form-feedback-valid {
  color: var(--success, green);
}

.apollo-form-feedback-indented {
  margin-left: 35px;
}