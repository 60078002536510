.phone-input {
    border: 1px solid var(--dark-gray,#a9a9a9);
    border-radius: 6px;
    padding: 13px 0 13px 10px ;
  }
  
  .phone-input:hover,
  .phone-input .react-phone-number-input--focus {
    border-color: var(--day, blue);
  }
  
  .phone-input.disabled {
    background-color: var(--light-smoke, whitesmoke);
    border-color: var(--marble, lightgray);
  }

  .phone-input .react-phone-number-input__row {
    height: 49.33px;
  }
  
  .phone-input .react-phone-number-input__input {
    border-bottom: none;
  }
  
  .phone-input .react-phone-number-input__input:disabled {
    background-color: var(--light-smoke, whitesmoke);
  }

  .phone-input .react-phone-number-input__input::-ms-clear {
    display: none;
  }
  
  .phone-input .react-phone-number-input__input:focus {
    outline: none;
  }

  .phone-input .react-phone-number-input__country-select:disabled {
      cursor: default;
  }
  .phone-input .PhoneInputInput{
    outline: none;
    border: none;
  }