.civic-reg-number .content-divider {
  border: 1px solid #F0F0F0;
  box-sizing: border-box;
  height: 1px;
}

.civic-reg-number .row:first-child {
  margin-top:16px;
}

.civic-reg-number .civic-birthdate {
  width: 160px;
  padding-right: 0;
}

.civic-reg-number .civic-last {
  width: 120px;
  display:flex;
  flex-direction: column;
  justify-content: space-around;  
}

.civic-reg-number .input-divider {
  box-sizing: border-box;
	height: 1px;
	width: 14px;
  border: 2px solid #C8C8C8;
}

.civic-reg-number .flex-row {
  display:flex;
  align-items: center;
  margin-top: 8px;
  margin-bottom: 16px;
}

.civic-reg-number .flex-row .apollo-form-feedback {
  position: absolute;
}

.react-phone-number-input__icon {
  border-width: 0;
}

.register-personal-info-form .apollo-form-group:last-child {
  padding-right: 1.5rem;
}

.register-personal-info-form .apollo-form-group .labels {
  display: flex;
  justify-content: space-between;
  max-width:370px;
}

.register-personal-info-form .social-security-number {
  display:flex;
  justify-content:space-between;
}

.register-personal-info-form .social-security-number-control {
  flex-direction: column;
  justify-content: end;
}

.register-personal-info-form .social-security-last-digits {
  display:flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-top: 36px;
}

.register-personal-info-form .social-security-last-digits .apollo-form-group {
  padding-top: 0;
}

.register-personal-info-form .social-security-last-digits .apollo-form-feedback {
  white-space: nowrap;
}

.register-personal-info-form .blue-info-box {
  text-align: center;
  background: #d9edf7;
  padding-left: 10px;
  margin-bottom: 10px;
  color: #31708f;
  border-color: #bce8f1;
  min-height: 3.5rem;
  display: block;
  padding: 1em;
}

.register-personal-info-form .blue-info-box a {
  text-decoration: underline;
  color: var(--midnight, darkblue);
}

@media (--mobile-only) {
  .register-personal-info-form .apollo-form-group:last-child {
    padding-right: 0;
  }

  .register-personal-info-form .social-security-number-country-input {
    min-width: unset;
  }

  .react-phone-number-input {
    width: 100%;
  }

  .register-personal-info-form .apollo-form-group .labels,
  .register-personal-info-form .apollo-form-group input,
  .register-personal-info-form .apollo-form-group .apollo-select {
    max-width: unset;
  }
}

@media (max-width: 320px) {
  .civic-reg-number .civic-birthdate {
    width: 140px;
  }
}