.register-partner-content .apollo-form-group .labels {
  display: flex;
  justify-content: space-between;
  max-width:370px;
}

.register-partner-content .react-phone-number-input__input--disabled {
  background-color: #f5f5f5;
  border-color: var(--marble,#d3d3d3);
}


@media (--mobile-only) {
  .register-partner-content .apollo-form-group .labels,
  .register-partner-content .apollo-form-group input {
    max-width: unset;
  }
}