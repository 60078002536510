.apollo-card-group {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

.apollo-card-group .apollo-card {
  margin-bottom: 15px;
}

@media (--mobile) {
  .apollo-card-group {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row wrap;
  }

  .apollo-card-group:not(.apollo-card-group-segmented) {
    margin-right: -15px;
    margin-left: -15px;
  }

  .apollo-card-group:not(.apollo-card-group-segmented) > .apollo-card {
    display: flex;
    flex: 1 0 0%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    flex-direction: column;
    margin-right: 15px;
    margin-bottom: 0;
    margin-left: 15px;
  }

  .apollo-card-group-segmented > .apollo-card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }

  .apollo-card-group-segmented > .apollo-card + .apollo-card {
    margin-left: 0;
    border-left: 0;
  }

  .apollo-card-group-segmented > .apollo-card:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .apollo-card-group-segmented > .apollo-card:first-child .apollo-card-image-top,
  .apollo-card-group-segmented > .apollo-card:first-child .apollo-card-header {
    border-top-right-radius: 0;
  }

  .apollo-card-group-segmented > .apollo-card:first-child .apollo-card-image-bottom,
  .apollo-card-group-segmented > .apollo-card:first-child .apollo-card-footer {
    border-bottom-right-radius: 0;
  }

  .apollo-card-group-segmented > .apollo-card:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .apollo-card-group-segmented > .apollo-card:last-child .apollo-card-image-top,
  .apollo-card-group-segmented > .apollo-card:last-child .apollo-card-header {
    border-top-left-radius: 0;
  }

  .apollo-card-group-segmented > .apollo-card:last-child .apollo-card-image-bottom,
  .apollo-card-group-segmented > .apollo-card:last-child .apollo-card-footer {
    border-bottom-left-radius: 0;
  }

  .apollo-card-group-segmented > .apollo-card:only-child {
    border-radius: 0.25rem;
  }

  .apollo-card-group-segmented > .apollo-card:only-child .apollo-card-image-top,
  .apollo-card-group-segmented > .apollo-card:only-child .apollo-card-header {
    border-top-left-radius: 0.25rem;
    border-top-right-radius: 0.25rem;
  }

  .apollo-card-group-segmented > .apollo-card:only-child .apollo-card-image-bottom,
  .apollo-card-group-segmented > .apollo-card:only-child .apollo-card-footer {
    border-bottom-right-radius: 0.25rem;
    border-bottom-left-radius: 0.25rem;
  }

  .apollo-card-group-segmented > .apollo-card:not(:first-child):not(:last-child):not(:only-child) {
    border-radius: 0;
  }

  .apollo-card-group-segmented > .apollo-card:not(:first-child):not(:last-child):not(:only-child) .apollo-card-image-top,
  .apollo-card-group-segmented > .apollo-card:not(:first-child):not(:last-child):not(:only-child) .apollo-card-image-bottom,
  .apollo-card-group-segmented > .apollo-card:not(:first-child):not(:last-child):not(:only-child) .apollo-card-header,
  .apollo-card-group-segmented > .apollo-card:not(:first-child):not(:last-child):not(:only-child) .apollo-card-footer {
    border-radius: 0;
  }
}
