.apollo-card-link {
  color: var(--day, blue);
  font-size: 16px;
  text-decoration: none;
}

.apollo-card-link:hover {
  color: var(--morning, lightblue);
  text-decoration: none;
}

.apollo-card-link + .apollo-card-link {
  margin-left: 1.25rem;
}
