.apollo-button-group {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

.apollo-button-group .apollo-button {
  margin-bottom: 15px;
}

@media (--mobile) {
  .apollo-button-group {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    flex-flow: row wrap;
    margin-left: 0;
  }

  .apollo-button-group:not(.apollo-button-group-segmented) > .apollo-button {
    margin-right: 5px;
    margin-bottom: 0;
    margin-left: 5px;
  }
  
  .apollo-button-group:not(.apollo-button-group-segmented) > .apollo-button:first-child { 
    margin-left: 0;
  }

  .apollo-button-group:not(.apollo-button-group-segmented) > .apollo-button:last-child {
    margin-right: 0;
  }

  .apollo-button-group-segmented > .apollo-button {
    margin: 0;
  }

  .apollo-button-group-segmented > .apollo-button + .apollo-button {
    margin-left: 0;
    border-left: 0;
  }

  .apollo-button-group-segmented > .apollo-button:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .apollo-button-group-segmented > .apollo-button:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .apollo-button-group-segmented > .apollo-button:only-child {
    border-radius: 6px;
  }

  .apollo-button-group-segmented > .apollo-button:not(:first-child):not(:last-child):not(:only-child) {
    border-radius: 0;
  }
}