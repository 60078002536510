.apollo-card {
  position: relative;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: var(--white, white);
  background-clip: border-box;
  border: 1px solid var(--sky, lightblue);
  border-radius: 6px;
}

.apollo-card-dark {
  background-color: var(--midnight, darkblue);
}

.apollo-card-dark .apollo-card-title,
.apollo-card-dark .apollo-card-text {
  color: var(--white, white);
}