.validation-modal-content {
    max-width: 600px;
  }
  
  .validation-modal-dialog {
    margin-top: 5px;
    text-align: left;
  }
  
  .validation-modal-footer {
    display: block;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
  }
  
  .validation-modal-content .error {
    text-align: right;
  }
  
  @media (min-height: 650px) {
    .validation-modal-dialog {
      margin-top: 150px;
    }
  }
  
  @media (min-width: 768px) {
    .validation-modal-footer {
      text-align: right;
    }
  }
  
  @media (min-width: 992px) {
    .validation-modal-footer {
      text-align: right;
    }
  }
  
  @media (min-width: 1200px) {
    .validation-modal-footer {
      text-align: right;
    }
  }