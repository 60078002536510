.register-future {
  position: relative;
  margin-top: 100px;
}

.register-future-content {
  min-height: calc(100vh - 310px);
}

.register-future-content h5 {
  text-align: left;
  padding-top: 32px;
}

.register-future-content p {
  text-align: left;
}

.register-future-content a {
    color: #3D7796;
}

.register-future-content .apollo-form-text {
  text-align: left;
}
.register-future-content .apollo-form-text {
  margin-left: 0;
}

@media (--mobile-only) {
  .register-future {
    position: static;
  }

  .register-future .apollo-container {
    justify-content: center;
  }

  .register-future .apollo-container > a {
    padding-right: 0;
  }

  .register-mobile-container {
    margin-bottom: 123px;
  }

  .register-future-content .apollo-form-group {
    padding-right: 0;
  }

  .register-future-content .apollo-form-group:last-child {
    padding-right: 0;
  }
 
  .register-future-content p {
    text-align: center;
  }

  .register-future-content h2 {
    font-size: 1.25em;
    text-align: center;
  }
}