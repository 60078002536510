:root {
  --black: #1e1e1e;
  --charcoal: #505050;
  --clear-sky: #0090d0;
  --cloud: #dee6ec;
  --danger: #ce5757;
  --danger-hover: #b14a4a;
  --dark-gray: #a3a3a3;
  --darker-gray: #5c5c5c;
  --day: #3d7796;
  --evening: #32647f;
  --light-smoke: #f6f6f6;
  --marble: #f0f0f0;
  --midnight: #24495c;
  --misty: #c8c8c8;
  --morning: #6593ad;
  --sky: #c2d3e0;
  --stone: #7c7c7c;
  --success: #67a799;
  --success-hover: #5c9286;
  --warning: #f6ce8e;
  --warning-hover: #efbf72;
  --white: #ffffff;
  /* --aa-primary-color-rgb: 128, 126, 163 !important; */
  --aa-primary-color-rgb: #1e1e1e !important;

}
