.apollo-dropdown-item {
  display: block;
  width: 100%;
  padding: 0.5rem 1rem;
  line-height: 19px;
  clear: both;
  font-size: 16px;
  font-weight: 100;
  font-family: inherit;
  color: var(--black, black);
  text-align: inherit;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  text-indent: 0px;
  text-rendering: auto;
  letter-spacing: normal;
  word-spacing: normal;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
  margin: 0;
  box-sizing: border-box;
  box-shadow: none;
}

.apollo-dropdown-item:hover,
.apollo-dropdown-item:focus {
  background-color: var(--cloud, lightblue);
  text-decoration: none;
  outline: none;
}

.apollo-dropdown-item:disabled {
  color: var(--stone, gray);
  background-color: transparent;
}

.apollo-dropdown-header {
  display: block;
  margin-left: .5rem;
  margin-right: .5rem;
  font-size: 16px;
  line-height: 19px;
  font-weight: 100;
  color: var(--stone, gray);
  white-space: nowrap;
  overflow: hidden;
}

.apollo-dropdown-header:after {
  content: '';
  display: inline-block;
  vertical-align: middle;
  border-top: 1px solid var(--marble, lightgray);
  width: 100%;
  margin: auto .5rem;
  clear: both;
}

.apollo-dropdown-separator {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid var(--marble, lightgray);
}
