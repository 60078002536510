.apollo-dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0.125rem 0 0;
  font-size: 1rem;
  color: var(--black, black);
  text-align: left;
  list-style: none;
  background-color: var(--white, white);
  background-clip: padding-box;
  border: 1px solid var(--marble, lightgray);
  border-radius: 4px;
  overflow: hidden;
}

.apollo-dropdown-menu.apollo-open {
  display: block;
}

.apollo-dropdown-menu-right {
  right: 0;
  left: auto;
}
