.apollo-mention {
  display: inline-flex;
  overflow: hidden;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  background-color: var(--cloud, lightblue);
  color: var(--black, black);
  margin-left: 2.5px;
  margin-right: 2.5px;
  border-radius: 6px;
  min-height: 26px;
  font-size: 16px;
  font-weight: 300;
};

.apollo-mention:first-child {
  margin-left: 0px;
  margin-right: 2.5px;
}

.apollo-mention:last-child {
  margin-left: 2.5px;
  margin-right: 0px;
}
