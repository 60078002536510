.apollo-dropdown-toggle {
  color: var(--charcoal, slategray);
  display: block;
  font-weight: 100;
  line-height: 19px;
  user-select: none;
  box-sizing: border-box;
  padding: 0.5rem 1rem;
  text-decoration: none;
  font-family: 'Campton-Book';
  font-size: 15px;
}

.apollo-dropdown-toggle:hover,
.apollo-dropdown-toggle:focus {
  color: var(--day, blue);
}

.apollo-dropdown-toggle.apollo-active,
.apollo-dropdown-toggle:active {
  color: var(--day, blue);
  text-shadow: 0 0 .01px var(--day, blue),
               0 0 .01px var(--day, blue);
}

.apollo-dropdown-toggle.apollo-disabled,
.apollo-dropdown-toggle:disabled {
  color: var(--misty, lightgray);
  text-shadow: none;
}

.apollo-dropdown-toggle:not(.apollo-disabled):not(:disabled) {
  cursor: pointer;
}

@media (max-width: 1280px) {
   .apollo-dropdown-toggle {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}
