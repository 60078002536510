.apollo-nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  list-style: none;
}

.apollo-navbar-nav {
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  flex-wrap: wrap;
  padding-left: 0;
  list-style: none;
  margin: 0;
}

.apollo-navbar-nav .apollo-nav-link,
.apollo-navbar-nav .apollo-dropdown-toggle,
.apollo-navbar-nav .apollo-breadcrumb {
  line-height: 70px;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.apollo-navbar-nav .apollo-breadcrumbs {
  margin-bottom: 0 !important;
}

.apollo-navbar-nav .apollo-dropdown-menu {
  position: static;
  float: none;
}

.apollo-nav-tabs {
  border-bottom: 1px solid var(--misty, lightgray);
}

.apollo-nav-tabs .apollo-nav-item {
  margin-bottom: -1px;
}

.apollo-nav-tabs .apollo-nav-link {
  border-bottom: 2px solid transparent;
}

.apollo-nav-tabs .apollo-nav-link.apollo-active,
.apollo-nav-tabs .apollo-nav-link:active {
  border-color: var(--day, blue);
}

.apollo-nav-vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
}

.apollo-nav-vertical.apollo-nav-tabs {
  border-bottom: none;
}

.apollo-nav-vertical.apollo-nav-tabs .apollo-nav-link {
  border-bottom: none;
  border-left: 2px solid transparent;
}

.apollo-nav-vertical.apollo-nav-tabs .apollo-nav-link.apollo-active,
.apollo-nav-vertical.apollo-nav-tabs .apollo-nav-link:active {
  border-left: 2px solid var(--day, blue);
}

.apollo-nav-justified .apollo-nav-item {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  text-align: center;
}

.apollo-nav-collapsed {
  display: none;
}

.apollo-nav-horizontal-left {
  -webkit-box-pack: start !important;
  -ms-flex-pack: start !important;
  justify-content: flex-start !important;
  padding-left: 0;
  padding-right: 0;
}

.apollo-nav-horizontal-center {
  -webkit-box-pack: center !important;
  -ms-flex-pack: center !important;
  justify-content: center !important;
  margin-left: auto;
  margin-right: auto;
}

.apollo-nav-horizontal-right {
  -webkit-box-pack: end !important;
  -ms-flex-pack: end !important;
  justify-content: flex-end !important;
  margin-left: auto;
  padding-right: 0;
}

.apollo-navbar-nav.apollo-nav-horizontal-center {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.apollo-nav-horizontal-left:not(.apollo-nav-vertical) .apollo-nav-item:first-child > .apollo-nav-link,
.apollo-nav-horizontal-left:not(.apollo-nav-vertical) .apollo-dropdown:first-child > .apollo-dropdown-toggle {
  padding-left: 0;
}

.apollo-nav-horizontal-right:not(.apollo-nav-vertical) .apollo-nav-item:last-child > .apollo-nav-link,
.apollo-nav-horizontal-right:not(.apollo-nav-vertical) .apollo-dropdown:last-child > .apollo-dropdown-toggle {
  padding-right: 0;
}

@media (max-width: 1280px) {
  .apollo-nav-horizontal-left {
    padding-left: 0 !important;
  }
}