@lost gutter 32px;

.column {
}

.column-sm {
  lost-column: 12/12;
}

.column-md {
  lost-column: 12/12;
}

.column-lg {
  lost-column: 12/12;
}

.column-xl {
  lost-column: 12/12;
}

@media only screen and (--mobile-only) {
  .column-mobile-hidden {
    display: none;
  }
}

@media only screen and (--mobile) {
  .column-mobile-1 {
    lost-column: 1/12;
  }
  .column-mobile-2 {
    lost-column: 2/12;
  }
  .column-mobile-3 {
    lost-column: 3/12;
  }
  .column-mobile-4 {
    lost-column: 4/12;
  }
  .column-mobile-5 {
    lost-column: 5/12;
  }
  .column-mobile-6 {
    lost-column: 6/12;
  }
  .column-mobile-7 {
    lost-column: 7/12;
  }
  .column-mobile-8 {
    lost-column: 8/12;
  }
  .column-mobile-9 {
    lost-column: 9/12;
  }
  .column-mobile-10 {
    lost-column: 10/12;
  }
  .column-mobile-11 {
    lost-column: 11/12;
  }
  .column-mobile-12 {
    lost-column: 12/12;
  }

  .column-offset-mobile-0 {
    lost-offset: 0;
  }
  .column-offset-mobile-1 {
    lost-offset: 1/12;
  }
  .column-offset-mobile-2 {
    lost-offset: 2/12;
  }
  .column-offset-mobile-3 {
    lost-offset: 3/12;
  }
  .column-offset-mobile-4 {
    lost-offset: 4/12;
  }
  .column-offset-mobile-5 {
    lost-offset: 5/12;
  }
  .column-offset-mobile-6 {
    lost-offset: 6/12;
  }
  .column-offset-mobile-7 {
    lost-offset: 7/12;
  }
  .column-offset-mobile-8 {
    lost-offset: 8/12;
  }
  .column-offset-mobile-9 {
    lost-offset: 9/12;
  }
  .column-offset-mobile-10 {
    lost-offset: 10/12;
  }
  .column-offset-mobile-11 {
    lost-offset: 11/12;
  }
  .column-offset-mobile-12 {
    lost-offset: 12/12;
  }
}

@media only screen and (--tablet-only) {
  .column-tablet-hidden {
    display: none;
  }
}

@media only screen and (--tablet) {
  .column-tablet-1 {
    lost-column: 1/12;
  }
  .column-tablet-2 {
    lost-column: 2/12;
  }
  .column-tablet-3 {
    lost-column: 3/12;
  }
  .column-tablet-4 {
    lost-column: 4/12;
  }
  .column-tablet-5 {
    lost-column: 5/12;
  }
  .column-tablet-6 {
    lost-column: 6/12;
  }
  .column-tablet-7 {
    lost-column: 7/12;
  }
  .column-tablet-8 {
    lost-column: 8/12;
  }
  .column-tablet-9 {
    lost-column: 9/12;
  }
  .column-tablet-10 {
    lost-column: 10/12;
  }
  .column-tablet-11 {
    lost-column: 11/12;
  }
  .column-tablet-12 {
    lost-column: 12/12;
  }

  .column-offset-tablet-0 {
    lost-offset: 0;
  }
  .column-offset-tablet-1 {
    lost-offset: 1/12;
  }
  .column-offset-tablet-2 {
    lost-offset: 2/12;
  }
  .column-offset-tablet-3 {
    lost-offset: 3/12;
  }
  .column-offset-tablet-4 {
    lost-offset: 4/12;
  }
  .column-offset-tablet-5 {
    lost-offset: 5/12;
  }
  .column-offset-tablet-6 {
    lost-offset: 6/12;
  }
  .column-offset-tablet-7 {
    lost-offset: 7/12;
  }
  .column-offset-tablet-8 {
    lost-offset: 8/12;
  }
  .column-offset-tablet-9 {
    lost-offset: 9/12;
  }
  .column-offset-tablet-10 {
    lost-offset: 10/12;
  }
  .column-offset-tablet-11 {
    lost-offset: 11/12;
  }
  .column-offset-tablet-12 {
    lost-offset: 12/12;
  }
}

@media only screen and (--desktop-only) {
  .column-desktop-hidden {
    display: none;
  }
}

@media only screen and (--desktop) {
  .column-desktop-1 {
    lost-column: 1/12;
  }
  .column-desktop-2 {
    lost-column: 2/12;
  }
  .column-desktop-3 {
    lost-column: 3/12;
  }
  .column-desktop-4 {
    lost-column: 4/12;
  }
  .column-desktop-5 {
    lost-column: 5/12;
  }
  .column-desktop-6 {
    lost-column: 6/12;
  }
  .column-desktop-7 {
    lost-column: 7/12;
  }
  .column-desktop-8 {
    lost-column: 8/12;
  }
  .column-desktop-9 {
    lost-column: 9/12;
  }
  .column-desktop-10 {
    lost-column: 10/12;
  }
  .column-desktop-11 {
    lost-column: 11/12;
  }
  .column-desktop-12 {
    lost-column: 12/12;
  }

  .column-offset-desktop-0 {
    lost-offset: 0;
  }
  .column-offset-desktop-1 {
    lost-offset: 1/12;
  }
  .column-offset-desktop-2 {
    lost-offset: 2/12;
  }
  .column-offset-desktop-3 {
    lost-offset: 3/12;
  }
  .column-offset-desktop-4 {
    lost-offset: 4/12;
  }
  .column-offset-desktop-5 {
    lost-offset: 5/12;
  }
  .column-offset-desktop-6 {
    lost-offset: 6/12;
  }
  .column-offset-desktop-7 {
    lost-offset: 7/12;
  }
  .column-offset-desktop-8 {
    lost-offset: 8/12;
  }
  .column-offset-desktop-9 {
    lost-offset: 9/12;
  }
  .column-offset-desktop-10 {
    lost-offset: 10/12;
  }
  .column-offset-desktop-11 {
    lost-offset: 11/12;
  }
  .column-offset-desktop-12 {
    lost-offset: 12/12;
  }
}

@media only screen and (--large-desktop) {
  .column-large-desktop-hidden {
    display: none;
  }
  .column-large-desktop-1 {
    lost-column: 1/12;
  }
  .column-large-desktop-2 {
    lost-column: 2/12;
  }
  .column-large-desktop-3 {
    lost-column: 3/12;
  }
  .column-large-desktop-4 {
    lost-column: 4/12;
  }
  .column-large-desktop-5 {
    lost-column: 5/12;
  }
  .column-large-desktop-6 {
    lost-column: 6/12;
  }
  .column-large-desktop-7 {
    lost-column: 7/12;
  }
  .column-large-desktop-8 {
    lost-column: 8/12;
  }
  .column-large-desktop-9 {
    lost-column: 9/12;
  }
  .column-large-desktop-10 {
    lost-column: 10/12;
  }
  .column-large-desktop-11 {
    lost-column: 11/12;
  }
  .column-large-desktop-12 {
    lost-column: 12/12;
  }

  .column-offset-large-desktop-0 {
    lost-offset: 0;
  }
  .column-offset-large-desktop-1 {
    lost-offset: 1/12;
  }
  .column-offset-large-desktop-2 {
    lost-offset: 2/12;
  }
  .column-offset-large-desktop-3 {
    lost-offset: 3/12;
  }
  .column-offset-large-desktop-4 {
    lost-offset: 4/12;
  }
  .column-offset-large-desktop-5 {
    lost-offset: 5/12;
  }
  .column-offset-large-desktop-6 {
    lost-offset: 6/12;
  }
  .column-offset-large-desktop-7 {
    lost-offset: 7/12;
  }
  .column-offset-large-desktop-8 {
    lost-offset: 8/12;
  }
  .column-offset-large-desktop-9 {
    lost-offset: 9/12;
  }
  .column-offset-large-desktop-10 {
    lost-offset: 10/12;
  }
  .column-offset-large-desktop-11 {
    lost-offset: 11/12;
  }
  .column-offset-large-desktop-12 {
    lost-offset: 12/12;
  }
}
