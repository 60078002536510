.apollo-navbar-toggle {
  padding: 0.25rem 0.75rem;
  font-size: 16px;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 6px;
}

.apollo-navbar-toggle:hover, .apollo-navbar-toggle:focus {
  text-decoration: none;
  outline: none;
}

.apollo-navbar-toggle:not(:disabled):not(.apollo-disabled) {
  cursor: pointer;
}

.apollo-navbar-toggle-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  content: "";
  background: no-repeat center center;
  background-size: 100% 100%;
}
