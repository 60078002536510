.af-navbar-brand {
  width: 123px;
}

.af-navbar-brand > img {
  max-height: 35px;
}

@media (--tablet) {
  .af-navbar-brand {
    margin-right: 5px;
  }
}