.apollo-nav-link {
  color: var(--charcoal, slategray);
  display: block;
  font-weight: 100;
  line-height: 19px;
  padding: 0.5rem 1rem;
  text-decoration: none;
  user-select: none;
  box-sizing: border-box;
  font-family: 'Campton-Book';
  font-size: 15px;
}

.apollo-nav-link:hover,
.apollo-nav-link:focus {
  color: var(--day, blue);
}

.apollo-nav-link.apollo-active,
.apollo-nav-link:active {
  color: var(--day, blue);
  text-shadow: 0 0 .01px var(--day, blue), 0 0 .01px var(--day, blue);
}

.apollo-nav-link.apollo-disabled,
.apollo-nav-link:disabled {
  color: var(--misty, lightgray);
  text-shadow: none;
}

.apollo-nav-link:not(.apollo-disabled):not(:disabled) {
  cursor: pointer;
}

@media (max-width: 1280px) {
  .apollo-nav-link {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }
}
