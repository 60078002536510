.confirm-active {
    position: relative;
    margin-top: 100px;
}


.confirm-active-content {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    margin-top: 160px;
}