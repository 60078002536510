.apollo-textarea {
  background-color: var(--white, white);
  border: 1px solid var(--dark-gray, darkgray);
  border-radius: 6px;
  font-family: inherit;
  font-size: 16px;
  line-height: inherit;
  margin: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 1rem;
  max-width: 400px;
  width: 100%;
}

.apollo-textarea:focus {
  outline: none;
}

.apollo-textarea:hover,
.apollo-textarea:focus {
  border-color: var(--day, blue);
}

.apollo-textarea:disabled {
  background-color: var(--light-smoke, whitesmoke);
  border-color: var(--marble, lightgray);
}

::placeholder {
  color: var(--dark-gray, darkgray) !important;
}
