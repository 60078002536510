.apollo-badge {
  border: 1px solid var(--day, blue);
  border-radius: 2px;
  font-size: 12px;
  font-weight: 500;
  line-height: 9px;
  text-align: center;
  text-transform: uppercase;
  padding: 0 .5rem;
  margin: 0 1px;
  user-select: none;
}

.apollo-badge:first-of-type {
  margin-left: 0;
}

.apollo-badge:last-of-type {
  margin-right: 0;
}

.apollo-badge-primary {
  color: var(--white, white);
  background-color: var(--day, blue);
}

.apollo-badge-secondary {
  color: var(--day, blue);
  background-color: var(--white, white);
}