.apollo-radio-button {
  display: inline-block;
  padding-right: 35px;
  position: relative;
  user-select: none;
}

.apollo-radio-button input {
  height: 0;
  opacity: 0;
  padding: 0;
  width: 0;
}

.apollo-radio-button .apollo-checkmark {
  border: 1px solid var(--stone, slategray);
  border-radius: 100px;
  height: 20px;
  left: 0;
  position: absolute;
  top: 3px;
  width: 20px;
}

.apollo-radio-button:hover input ~ .apollo-checkmark {
  border: 2px solid var(--day, blue);
  cursor: pointer;
  height: 18px;
  width: 18px;
}

.apollo-radio-button input:checked ~ .apollo-checkmark {
  background-color: var(--white, white);
  border: 2px solid var(--day, blue);
  height: 18px;
  width: 18px;
}

.apollo-radio-button input:disabled ~ .apollo-checkmark {
  background-color: var(--white, white);
  border-color: var(--misty, lightgray);
  cursor: default;
}

.apollo-radio-button .apollo-checkmark:after {
  content: "";
  display: none;
  position: absolute;
}

.apollo-radio-button input:checked ~ .apollo-checkmark:after {
  display: block;
}

.apollo-radio-button .apollo-checkmark:after {
  background-color: var(--day, blue);
  border-radius: 50%;
  height: 10px;
  left: 2px;
  width: 10px;
  top: 2px;
}

.apollo-radio-button input:disabled ~ .apollo-checkmark:after {
  background-color: var(--misty, lightgray);
}