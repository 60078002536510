.apollo-button {
  background-color: var(--white, white);
  border: 1px solid var(--dark-gray, darkgray);
  border-radius: 6px;
  color: var(--charcoal, slategray);
  display: inline-block;
  font-family: inherit;
  font-size: 16px;
  height: 48px;  
  margin: 0;
  overflow: visible;
  line-height: inherit;
  text-align: center;
  padding-left: 28px;
  padding-right: 28px;
  margin-left: 2.5px;
  margin-right: 2.5px;
  min-width: 154px;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
  position: relative;
}

.apollo-button:first-child {
  margin-left: 0px;
}

.apollo-button:last-child {
  margin-right: 0px;
}

.apollo-button:hover {
  border-color: var(--day, blue);
  color: var(--day, blue);
}

.apollo-button:not(:disabled):hover {
  cursor: pointer;
}

.apollo-button:focus {
  outline: none;
}

.apollo-button:disabled,
.apollo-button:disabled:hover {
  background-color: var(--marble, whitesmoke);
  border-color: var(--marble, whitesmoke);
  color: var(--dark-gray, darkgray);
}

.apollo-button-link:disabled,
.apollo-button-link:disabled:hover {
  background-color: transparent;
  border-color: transparent;
}

.apollo-button-primary {
  background-color: var(--day, blue);
  border-color: var(--day, blue);
  color: var(--white, white);
}

.apollo-button-primary:hover {
  background-color: var(--evening, darkblue);
  border-color: var(--evening, darkblue);
  color: var(--white, white);
}

.apollo-button-danger { 
  background-color: var(--danger, red);
  border-color: var(--danger, red);
  color: var(--white, white);
}

.apollo-button-danger:hover {
  background-color: var(--danger-hover, darkred);
  border-color: var(--danger-hover, darkred);
  color: var(--white, white);
}

.apollo-button-success {
  background-color: var(--success, green);
  border-color: var(--success, green);
  color: var(--white, white);
}

.apollo-button-success:hover {
  background-color: var(--success-hover, darkgreen);
  border-color: var(--success-hover, darkgreen);
  color: var(--white, white);
}

.apollo-button-warning {
  background-color: var(--warning, yellow);
  border-color: var(--warning, yellow);
  color: var(--white, white);
}

.apollo-button-warning:hover {
  background-color: var(--warning-hover, darkgoldenrod);
  border-color: var(--warning-hover, darkgoldenrod);
  color: var(--white, white);
}

.apollo-button-link {
  color: var(--day, blue);
}

.apollo-button-link,
.apollo-button-link:hover,
.apollo-button-link:focus {
  background-color: transparent;
  border-color: transparent;
}

.apollo-button-link:hover,
.apollo-button-link:focus {
  color: var(--morning, lightblue);
}

.apollo-button-small {
  height: 40px;
  min-width: 144px;
}

.apollo-button-large {
  height: 56px;
  min-width: 145px;
}

a.apollo-button {
  text-decoration: none;
  line-height: 48px;
}

a.apollo-button-small {
  line-height: 40px;
}

a.apollo-button-large {
  line-height: 56px;
}

.apollo-button-loading .apollo-loader {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--marble, whitesmoke);
  border-radius: 6px;
}
