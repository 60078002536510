.apollo-icon {
  display: inline-block;
  vertical-align: middle;
  margin: auto;
  fill: var(--day, blue);
}

.apollo-icon-x-small {
  width: 16px;
  height: 16px;
}

.apollo-icon-small {
  width: 32px;
  height: 32px;
}

.apollo-icon-medium {
  width: 48px;
  height: 48px;
}

.apollo-icon-large {
  width: 64px;
  height: 64px;
}
