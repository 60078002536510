.apollo-navbar-brand {
  display: inline-block;
  font-weight: 100;
  padding: 0;
  text-decoration: none;
  white-space: nowrap;
  font-family: 'Campton-Book';
  font-size: 19px;
  line-height: normal;  
}

.apollo-navbar-brand:hover,
.apollo-navbar-brand:focus {
  text-decoration: none;
}

.apollo-navbar-brand:not(.apollo-disabled):not(:disabled) {
  cursor: pointer;
}

.apollo-navbar-brand.apollo-active {

}

.apollo-navbar-brand > img {
  max-height: 40px;
  width: auto;
  vertical-align: middle;
}
