.settings-tooltip {
  display:flex;
  flex-direction: row;
  align-items: flex-end;
}

.settings-tooltip > * {
  margin-bottom: 0;
}

.settings-tooltip .tooltip-positioner {
  display:flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 16px;
  height: 22px;
}

.settings-tooltip .tooltip-positioner .tooltip-parent {
  height: 16px;
  width: 16px;
  background-color: black;
  border-radius: 50%;
  cursor: pointer;
  font-weight: bold;
  font-size: large;
  display:flex;
  align-items: center;
  justify-content: center;  
}

.settings-tooltip .tooltip-parent .tooltip {
  text-align:center;
  color: white;
  font-size: 12px;
  line-height: 12px;
}

.settings-tooltip .tooltip .tooltip-text {
  visibility: hidden;
  max-width: 360px;
  background-color: black;
  color: #fff;
  text-align: left;
  border-radius: 6px;
  padding: 5px 5px;
  position: absolute;
  z-index: 1;
}

.settings-tooltip .tooltip:hover .tooltip-text {
  visibility: visible;
}