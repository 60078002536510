.phone2-container {
    height: 49.33px;
    width: 100%;
    font-family: inherit;
  }
  
.phone2-input {
  max-width: 370px;
  width: 100% !important;
  height: 49.33px !important;
  font-size: 16px !important;
  border: 1px solid var(--dark-gray,#a9a9a9) !important;
  border-radius: 6px;
  background-color: #fff !important;
  display: none;
}

.phone2-input:disabled {
  cursor: initial;
  background-color: #f5f5f5 !important;
}

.phone2-dropdown-btn {
  border: 1px solid var(--dark-gray,#a9a9a9) !important;;
  border-radius: 6px 0 0 6px;
}

.phone2-dropdown-btn .arrow {
  display: block;
  float: unset;
  margin-left: 0;
  border: unset;
  padding: 0;
  transform: unset;
}

.phone2-search .search-box {
  width: 90%;
  height: 2rem;
}