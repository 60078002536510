.alert-modal-content {
    max-width: 550px;
  }
  
  .alert-modal-dialog {
    margin-top: 160px;
    text-align: left;
  }
  
  .alert-modal-footer {
    text-align: center;
  }
  