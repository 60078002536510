.register-footer {
  margin-top: 95px;
  height: 115px;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-flow: row nowrap;
  -webkit-box-pack: start;
  justify-content: flex-start;
  box-shadow: 0 0 16px 0 rgba(28,37,49,0.08);
}

  .register-footer .register-control {
    padding-top: 25px;
    margin-left: 20%;
    margin-right: 25%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .register-footer .register-control p {
    max-width: 50%;
  }

  .register-footer .register-control a {
    color: #3D7796;
  }

  .register-footer .register-control .button-placeholder {
    width: 158px;
  }

  .register-footer-mobile-container {
    background: white;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 25px 20px;
    box-shadow: 0 0 16px 0 rgba(28,37,49,0.08);
  }

  .register-footer-mobile {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  @media (max-width: 321px) {
    .register-footer-mobile .apollo-button {
      min-width: 124px;
    }
  }

  @media (--tablet-only) {
    .register-footer {
      height: 100%;
    }
  }

  @media (--mobile-only) {
    .register-footer {
      display: none;
    }
  }

  @media (--tablet) {
    .register-footer-mobile-container {
      display: none;
    }
  }