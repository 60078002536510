.layout {
  position: relative;
  width: 100%;
  margin-bottom: 70px;
  padding-bottom: 50px;
  margin-top: 25px;
}

@media (min-width: 992px) {
  .layout {
    margin-bottom: 370px;
    padding-bottom: 50px;
    margin-top: 50px;
  }
}

@media (min-width: 1200px) {
  .layout {
    margin-bottom: 370px;
    padding-bottom: 50px;
    margin-top: 50px;
  }
}

.layout > div {
  width: 100%;
}
