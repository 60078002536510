.apollo-modal {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1050;
  display: none;
  overflow: hidden;
  outline: none;
}

.apollo-modal-dialog {
  position: relative;
  margin: 1rem;
  pointer-events: none;
}

.apollo-modal-content {
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  display: flex;
  width: 100%;
  padding: 1rem;
  margin: 0 auto;
  pointer-events: auto;
  background-color: var(--white, white);
  background-clip: padding-box;
  border: 1px solid var(--sky, lightblue);
  border-radius: 6px;
  outline: 0;
  max-width: 800px;
}

.apollo-modal-backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1040;
  background-color: var(--black, black);
  opacity: .5;
}

@-webkit-keyframes slide-enter {
  from {
      -webkit-transform: translate3d(0, -100px, 0);
      transform: translate3d(0, -100px, 0);
  }
}

@keyframes slide-enter {
  from {
      -webkit-transform: translate3d(0, -100px, 0);
      transform: translate3d(0, -100px, 0);
  }
}

@-webkit-keyframes slide-exit {
  to {
      -webkit-transform: translate3d(0, -100px, 0);
      transform: translate3d(0, -100px, 0);
  }
}

@keyframes slide-exit {
  to {
      -webkit-transform: translate3d(0, -100px, 0);
      transform: translate3d(0, -100px, 0);
  }
}

@-webkit-keyframes fade-enter {
  from {
      opacity: 0;
  }
}

@keyframes fade-enter {
  from {
      opacity: 0;
  }
}

@-webkit-keyframes fade-exit {
  to {
      opacity: 0
  }
}

@keyframes fade-exit {
  to {
      opacity: 0
  }
}

.apollo-modal.apollo-show .apollo-modal-dialog {
  -webkit-animation: slide-enter both cubic-bezier(0.4, 0, 0, 1.5), fade-enter both ease-in;
  animation: slide-enter both cubic-bezier(0.4, 0, 0, 1.5), fade-enter both ease-in;
  animation-duration: .5s;
}

.apollo-modal.apollo-hide .apollo-modal-dialog {
  -webkit-animation: slide-exit both, fade-exit both ease-out;
  animation: slide-exit both, fade-exit both ease-out;
  animation-duration: .5s;
}