.not-found {
  text-align: left;
  padding: 15px;
  margin: auto;
  margin-top: 50px;
  width: 550px;
  border: 1px solid var(--marble, lightgray);
  border-radius: 6px;
}

.not-found a {
  text-align: left;
  padding: 0;
  margin: 5px 0 0 0;
  color: var(--day, blue);
}

.not-found a:hover,
.not-found a:focus {
  color: var(--morning, lightblue);
}

.not-found-title {
  font-weight: bold;
}

.not-found-message {
  font-size: 16px;
}
